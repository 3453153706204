import React from 'react'
import { LayoutA }from "../layouts";
import CONFIG from '../template-config'

class PrivacyPolicy extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="PolicyPage">
          <section className="grid-restrain-narrow text-background">
            <div className="title-container">
              <div className="title">Terms & Conditions</div>
            </div>
            <div className="section">
              <p>This agreement applies to {CONFIG.domain}, a website and online service of {CONFIG.creator} (“{CONFIG.product},”
                “we,” or “us”). This page explains the terms by which you may use our online services, website, and
                software provided on or in connection with the service (collectively the “Service”). By accessing or
                using the Service, you signify that you have read, understood, and agree to be bound by this Terms of
                Service Agreement (“Agreement”) and to the collection and use of your information as set forth in the
                {CONFIG.product} Privacy Policy, whether or not you are a registered user of our Service. This Agreement applies
                to all visitors, users, and others who access the Service (“Users”).</p>
              <p>PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT
                CONTAINS A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN
                INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.</p>
            </div>
            <div className="section">
              <ul>
                <li>Use of Our Service</li>
                <li>Our Proprietary Rights</li>
                <li>Paid Services</li>
                <li>No Professional Advice</li>
                <li>Privacy</li>
                <li>Security</li>
                <li>Third-Party Links</li>
                <li>Indemnity</li>
                <li>No Warranty</li>
                <li>Limitation of Liability</li>
                <li>Governing Law and Arbitration</li>
                <li>General</li>
                <li>Changes to Our Terms &amp; Conditions</li>
              </ul>
            </div>
            <h2>1. Use of Our Service</h2>
            <div className="section">
              <h5>A. Eligibility</h5>
              <p>You may use the Service only if you can form a binding contract with {CONFIG.product}, and only in compliance
                with this Agreement and all applicable local, state, national, and international laws, rules and
                regulations. Any use or access to the Service by anyone under 13 is strictly prohibited and in violation
                of this Agreement. The Service is not available to any Users previously removed from the Service by
                {CONFIG.product}.</p>
            </div>
            <div className="section">
              <h5>B. {CONFIG.product} Service.</h5>
              <p>Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited,
                non-transferable, freely revocable license to use the Service as permitted by the features of the
                Service. {CONFIG.product} reserves all rights not expressly granted herein in the Service and the {CONFIG.product}
                Content (as defined below). {CONFIG.product} may terminate this license at any time for any reason or no
                reason.</p>
            </div>
            <div className="section">
              <h5>C. {CONFIG.product} Accounts</h5>
              <p>Your {CONFIG.product} account gives you access to the services and functionality that we may establish and
                maintain from time to time and in our sole discretion. We may maintain different types of accounts for
                different types of Users. If you open a {CONFIG.product} account on behalf of a company, organization, or other
                entity, then (a) “you” includes you and that entity, and (b) you represent and warrant that you are an
                authorized representative of the entity with the authority to bind the entity to this Agreement, and
                that you agree to this Agreement on the entity’s behalf.</p>
              <p>You may never use another User’s account without permission. When creating your account, you must
                provide accurate and complete information. You are solely responsible for the activity that occurs on
                your account, and you must keep your account password secure. We encourage you to use “strong” passwords
                (passwords that use a combination of upper and lower case letters, numbers and symbols) with your
                account. You must notify {CONFIG.product} immediately of any breach of security or unauthorized use of your
                account. {CONFIG.product} will not be liable for any losses caused by any unauthorized use of your account.</p>
              <p>You may control your User profile and how you interact with the Service by changing the settings in
                your user profile settings. By providing {CONFIG.product} your email address you consent to our using the email
                address to send you Service-related notices, including any notices required by law, in lieu of
                communication by postal mail. We may also use your email address to send you other messages, such as
                changes to features of the Service and special offers. If you do not want to receive such email
                messages, you may opt out or change your preferences in your user profile settings. Opting out may
                prevent you from receiving email messages regarding updates, improvements, or offers.</p>
            </div>
            <div className="section">
              <h5>D. Service Rules</h5>
              <p>You agree not to engage in any of the following prohibited activities:</p>
              <ul>
                <li>(i) copying, distributing, or disclosing any part of the Service in any medium, including without
                  limitation by any automated or non-automated “scraping”;
                </li>
                <li>(ii) using any automated system, including without limitation “robots,” “spiders,” “offline
                  readers,” etc., to access the Service in a manner that sends more request messages to the {CONFIG.product}
                  servers than a human can reasonably produce in the same period of time by using a conventional on-line
                  web browser or through the normal course of API consumption (at our sole discretion);
                </li>
                <li>(iii) transmitting spam, chain letters, or other unsolicited email;</li>
                <li>(iv) attempting to interfere with, compromise the system integrity or security or decipher any
                  transmissions to or from the servers running the Service;
                </li>
                <li>(iv) attempting to interfere with, compromise the system integrity or security or decipher any
                  transmissions to or from the servers running the Service;
                </li>
                <li>(v) taking any action that imposes, or may impose at our sole discretion an unreasonable or
                  disproportionately large load on our infrastructure;
                </li>
                <li>(vi) uploading invalid data, viruses, worms, or other software agents through the Service;</li>
                <li>(vii) collecting or harvesting any personally identifiable information, including account names,
                  from the Service;
                </li>
                <li>(viii) using the Service for any commercial solicitation purposes;</li>
                <li>(ix) impersonating another person or otherwise misrepresenting your affiliation with a person or
                  entity, conducting fraud, hiding or attempting to hide your identity;
                </li>
                <li>(x) interfering with the proper working of the Service;</li>
                <li>(xi) accessing any content on the Service through any technology or means other than those provided
                  or authorized by the Service;
                </li>
                <li>or (xii) bypassing the measures we may use to prevent or restrict access to the Service, including
                  without limitation features that prevent or restrict use or copying of any content or enforce
                  limitations on use of the Service or the content therein.
                </li>
              </ul>
              <p>We may, without prior notice, change the Service; stop providing the Service or features of the
                Service, to you or to users generally; or create or modify usage limits for the Service. We may
                permanently or temporarily terminate or suspend your access to the Service without notice and liability
                for any reason, including if in our sole determination you violate any provision of this Agreement, or
                for no reason. Upon termination for any reason or no reason, you continue to be bound by this
                Agreement.</p>
              <p>You are solely responsible for your interactions with other {CONFIG.product} Users. We reserve the right, but
                have no obligation, to monitor disputes between you and other Users. {CONFIG.product} shall have no liability
                for your interactions with other Users, or for any User’s action or inaction.</p>
            </div>

            <h2>2. Our Proprietary Rights</h2>
            <div className="section">
              <p>Except for your User Content, the Service and all materials therein or transferred thereby, including,
                without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks, service
                marks, copyrights, photographs, audio, videos and User Content belonging to other Users (the “{CONFIG.product}
                Content”), and all Intellectual Property Rights related thereto, are the exclusive property of {CONFIG.product}
                and its licensors (including other Users who post User Content to the Service). Except as explicitly
                provided herein, nothing in this Agreement shall be deemed to create a license in or under any such
                Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute, copy,
                reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works
                from any {CONFIG.product} Content. Use of the {CONFIG.product} Content for any purpose not expressly permitted by this
                Agreement is strictly prohibited.</p>
              <p>You may choose to or we may invite you to submit comments or ideas about the Service, including without
                limitation about how to improve the Service or our products (“Ideas”). By submitting any Idea, you agree
                that your disclosure is gratuitous, unsolicited and without restriction and will not place {CONFIG.product}
                under any fiduciary or other obligation, and that we are free to use the Idea without any additional
                compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You
                further acknowledge that, by acceptance of your submission, {CONFIG.product} does not waive any rights to use
                similar or related ideas previously known to {CONFIG.product}, or developed by its employees, or obtained from
                sources other than you.</p>
            </div>
            <h2>3. Paid Services</h2>
            <div className="section">
              <p><b>A. Billing Policies</b> Certain aspects of the Service may be provided for a fee or other charge. If
                you elect to use paid aspects of the Service, you agree to the pricing and payment terms presented, as
                we may update them from time to time. {CONFIG.product} may add new services for additional fees and charges, or
                amend fees and charges for existing services, at any time in its sole discretion. Any change to our
                pricing or payment terms shall become effective in the billing cycle following notice of such change to
                you as provided in this Agreement.</p>
              <p><b>B. No Refunds.</b> You may cancel your {CONFIG.product} account at any time; however, there are no refunds
                for cancellation. In the event that {CONFIG.product} suspends or terminates your account or this Agreement for
                your breach of this Agreement, you understand and agree that you shall receive no refund or exchange for
                any unused time or data usage on a subscription, any license or subscription fees for any portion of the
                Service, any content or data associated with your account, or for anything else.</p>
              <p><b>C. Payment Information; Taxes.</b> All information that you provide in connection with a purchase or
                transaction or other monetary transaction interaction with the Service must be accurate, complete, and
                current. You agree to pay all charges incurred by users of your credit card, debit card, or other
                payment method used in connection with a purchase or transaction or other monetary transaction
                interaction with the Service at the prices in effect when such charges are incurred. You will pay any
                applicable taxes, if any, relating to any such purchases, transactions or other monetary transaction
                interactions.</p>
            </div>
            <h2>4. No Professional Advice</h2>
            <div className="section">
              <p>If the Service provides professional information (for example, regarding technical security), such
                information is for informational purposes only and should not be construed as professional advice. No
                action should be taken based upon any information contained in the Service. You should seek independent
                professional advice from a person who is licensed and/or qualified in the applicable area.</p>
            </div>

            <h2>5. Privacy</h2>
            <div className="section">
              <p>We care about the privacy of our Users. You understand that by using the Services you consent to the
                collection, use and disclosure of your personally identifiable information and aggregate data as set
                forth in our <a href="/privacy-policy">Privacy Policy</a>, and to have your personally identifiable
                information collected, used, transferred to and processed in the United States.</p>
            </div>

            <h2>6. Security</h2>
            <div className="section">
              <p>{CONFIG.product} uses commercially reasonable physical, managerial, and technical safeguards to preserve the
                integrity and security of your personal information and implement your privacy settings. However, we
                cannot guarantee that unauthorized third parties will never be able to defeat our security measures or
                use your personal information for improper purposes. You acknowledge that you provide your personal
                information at your own risk.</p>
            </div>

            <h2>7. Third-Party Links</h2>
            <div className="section">
              <p>The Service may contain links to third-party websites, services or other events or activities that are
                not owned or controlled by {CONFIG.product}. {CONFIG.product} does not endorse or assume any responsibility for any such
                third-party sites, information, materials, products, or services. If you access a third party website
                from the Service, you do so at your own risk, and you understand that this Agreement and {CONFIG.product}’s
                Privacy Policy do not apply to your use of such sites. You expressly relieve {CONFIG.product} from any and all
                liability arising from your use of any third-party website, service, or content.</p>
            </div>

            <h2>8. Indemnity</h2>
            <div className="section">
              <p>You agree to defend, indemnify and hold harmless {CONFIG.product} and its subsidiaries, agents, licensors,
                managers, and other affiliated companies, and their employees, contractors, agents, officers and
                directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorney’s fees) arising out of or related to:</p>
              <h4>Indemnity:</h4>
              <ul>
                <li>(i) your use of and access to the Service, including any data or content transmitted or received by
                  you;
                </li>
                <li>(ii) your violation of any term of this Agreement, including without limitation your breach of any
                  of the representations and warranties above;
                </li>
                <li>(iii) your violation of any third-party right, including without limitation any right of privacy or
                  Intellectual Property Rights;
                </li>
                <li>(iv) your violation of any applicable law, rule or regulation;</li>
                <li>(v) User Content or any other information or content that is submitted via your account including
                  without limitation misleading, false or inaccurate information;
                </li>
                <li>(vi) negligent or willful misconduct;</li>
                <li>or (vii) any other party’s access and use of the Service with your unique username, password or
                  other appropriate security code.
                </li>
              </ul>
            </div>

            <h2>9. No Warranty</h2>
            <div className="section">
              <p>THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK.
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY
                KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                WRITTEN, OBTAINED BY YOU FROM {CONFIG.product} OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY
                STATED HEREIN. WITHOUT LIMITING THE FOREGOING, {CONFIG.product}, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS
                LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET
                YOUR REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED
                OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
                DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.</p>
              <p>SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO
                YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM
                STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT
                PROHIBITED BY APPLICABLE LAW.</p>
            </div>

            <h2>10. Limitation of Liability</h2>
            <div className="section">
              <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL {CONFIG.product}, ITS AFFILIATES, AGENTS,
                DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL,
                USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE,
                THIS SERVICE. UNDER NO CIRCUMSTANCES WILL {CONFIG.product} BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY
                RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT OR
                THE INFORMATION CONTAINED THEREIN.</p>
              <h4>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, {CONFIG.product} ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
                ANY:</h4>
              <ul>
                <li>(I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;</li>
                <li>(II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR
                  USE OF OUR SERVICE;
                </li>
                <li>(III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                  INFORMATION STORED THEREIN;
                </li>
                <li>(IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE;</li>
                <li>(V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE
                  BY ANY THIRD PARTY;
                </li>
                <li>(VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE
                  USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE;
                </li>
                <li>AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO
                  EVENT SHALL {CONFIG.product}, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE
                  TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT
                  EXCEEDING THE AMOUNT YOU PAID TO {CONFIG.product} HEREUNDER OR $100.00, WHICHEVER IS GREATER.
                </li>
              </ul>
              <p>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
                NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF {CONFIG.product} HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW
                IN THE APPLICABLE JURISDICTION.</p>
              <p>SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS,
                AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE EXCLUSIONS AND LIMITATIONS OF
                LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
              <p>The Service is controlled and operated from facilities in the United States. {CONFIG.product} makes no
                representations that the Service is appropriate or available for use in other locations. Those who
                access or use the Service from other jurisdictions do so at their own volition and are entirely
                responsible for compliance with all applicable United States and local laws and regulations, including
                but not limited to export and import regulations. You may not use the Service if you are a resident of a
                country embargoed by the United States, or are a foreign person or entity blocked or denied by the
                United States government. Unless otherwise explicitly stated, all materials found on the Service are
                solely directed to individuals, companies, or other entities located in the United States.</p>
            </div>

            <h2>11. Governing Law and Arbitration.</h2>
            <div className="section">
              <h5>A. Governing Law.</h5>
              <p>You agree that: (i) the Service shall be deemed solely based in California; and (ii) the Service shall
                be deemed a passive one that does not give rise to personal jurisdiction over {CONFIG.product}, either specific
                or general, in jurisdictions other than California. This Agreement shall be governed by the internal
                substantive laws of the State of California, without respect to its conflict of laws principles. The
                parties acknowledge that this Agreement evidences a transaction involving interstate commerce.
                Notwithstanding the preceding sentences with respect to the substantive law, any arbitration conducted
                pursuant to the terms of this Agreement shall be governed by the Federal Arbitration Act (9 U.S.C. §§
                1-16). The application of the United Nations Convention on Contracts for the International Sale of Goods
                is expressly excluded. You agree to submit to the personal jurisdiction of the federal and state courts
                located in Santa Clara County, California for any actions for which we retain the right to seek
                injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or
                threatened infringement, misappropriation or violation of a our copyrights, trademarks, trade secrets,
                patents, or other intellectual property or proprietary rights, as set forth in the Arbitration provision
                below.</p>
              <h5>B. Arbitration.</h5>
              <p>READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE
                MANNER IN WHICH YOU CAN SEEK RELIEF FROM {CONFIG.product}. In the unlikely event that {CONFIG.product} has not been able
                to resolve a dispute it has with you after 60 days, we each agree to resolve any claim, dispute, or
                controversy (excluding any {CONFIG.product} claims for injunctive or other equitable relief) arising out of or
                in connection with or relating to these Terms of Service, or the breach or alleged breach thereof
                (collectively, “Claims”), by binding arbitration by the Judicial Mediation and Arbitration Services
                (“JAMS”) under the Optional Expedited Arbitration Procedures then in effect for JAMS, except as provided
                herein. The arbitration will be conducted in Santa Clara County, California, unless you and {CONFIG.product}
                agree otherwise. Each party will be responsible for paying any JAMS filing, administrative and
                arbitrator fees in accordance with JAMS rules. The award rendered by the arbitrator shall include costs
                of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses, and any
                judgment on the award rendered by the arbitrator may be entered in any court of competent
                jurisdiction.</p>
              <p>Nothing in this Section shall be deemed as preventing {CONFIG.product} from seeking injunctive or other
                equitable relief from the courts as necessary to protect any of {CONFIG.product}’s proprietary interests. ALL
                CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE
                PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY
                NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS TERMS OF SERVICE,
                YOU AND {CONFIG.product} ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION,
                .COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.</p>
            </div>

            <h2>12. General</h2>
            <div className="section">
              <h5>A. Assignment.</h5>
              <p>This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by
                you, but may be assigned by {CONFIG.product} without restriction. Any attempted transfer or assignment in
                violation hereof shall be null and void.</p>
              <h5>B. Notification Procedures and Changes to the Agreement.</h5>
              <p>{CONFIG.product} may provide notifications, whether such notifications are required by law or are for marketing
                or other business related purposes, to you via email notice, written or hard copy notice, or through
                posting of such notice on our website, as determined by {CONFIG.product} in our sole discretion. {CONFIG.product}
                reserves the right to determine the form and means of providing notifications to our Users, provided
                that you may opt out of certain means of notification as described in this Agreement. {CONFIG.product} is not
                responsible for any automatic filtering you or your network provider may apply to email notifications we
                send to the email address you provide us. {CONFIG.product} may, in its sole discretion, modify or update this
                Agreement from time to time, and so you should review this page periodically. When we change the
                Agreement in a material manner, we will update the ‘last modified’ date at the bottom of this page. Your
                continued use of the Service after any such change constitutes your acceptance of the new Terms of
                Service. If you do not agree to any of these terms or any future Terms of Service, do not use or access
                (or continue to access) the Service.</p>
              <h5>C. Entire Agreement/Severability.</h5>
              <p>This Agreement, together with any amendments and any additional agreements you may enter into with
                {CONFIG.product} in connection with the Service, shall constitute the entire agreement between you and {CONFIG.product}
                concerning the Service. If any provision of this Agreement is deemed invalid by a court of competent
                jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions
                of this Agreement, which shall remain in full force and effect, except that in the event of
                unenforceability of the universal Class Action/Jury Trial Waiver, the entire arbitration agreement shall
                be unenforceable.</p>
              <h5>D. No Waiver.</h5>
              <p>No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or
                any other term, and {CONFIG.product}’s failure to assert any right or provision under this Agreement shall not
                constitute a waiver of such right or provision.</p>
              <h5>E. Government Use Rights.</h5>
              <p>If the Service is licensed to the United States government or any agency thereof, then the Service will
                be deemed to be “commercial computer software” and “commercial computer software documentation,”
                respectively, pursuant to DFAR Section 227.7202 and FAR Section 12.212, as applicable. Any use,
                reproduction, release, performance, display or disclosure of the Service and any accompanying
                documentation by the U.S. Government will be governed solely by these Terms of Service and is prohibited
                except to the extent expressly permitted by these Terms of Service.</p>
              <h5>F. Contact.</h5>
              <p>Please contact us at joe.{CONFIG.product}.io@gmail.com with any questions regarding this Agreement.</p>
            </div>

            <h2>13. Changes to Our Terms &amp; Conditions</h2>
            <div className="section">
              <p>{CONFIG.product} may modify or update this Terms &amp; Conditions from time to time to reflect the changes in
                our business and practices, and so you should review this page periodically. When we change the terms in
                a material manner we will let you know and update the ‘last modified’ date at the bottom of this
                page.</p>
            </div>
          </section>
        </div>
      </LayoutA>
    )
  }

}

export default PrivacyPolicy;
